import React from 'react';
import { Region } from '@enonic/react-components';

import './twoColumn.scss';
import { TwoColumnPropsType } from './type.d'

function TwoColumn(props: Readonly<TwoColumnPropsType>) {
  const { leftRegionData, leftRegionName, rightRegionData, rightRegionName, backgroundColor } = props

  return (
    <div className={`w-full ${backgroundColor}`}>
      <div className="l-container mx-auto">
        <div className="columns-1 tablet:columns-2 block tablet:flex break-all">
          <div className="w-full tablet:pr-sm pr-0">
            <Region name={leftRegionName} regionData={leftRegionData} />
          </div>
          <div className="w-full tablet:pl-sm pl-0">
            <Region name={rightRegionName} regionData={rightRegionData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default (props: Readonly<TwoColumnPropsType>) => <TwoColumn {...props} />;
